@import "../variables";


.InventoryTable {
  // border: 1px solid black;
  position: relative;
  width: 100%;
  height: 94%;
  box-sizing: border-box;
  background-color: var(--primaryColor);
  //border: 1px solid black;
  //display: flex;
  //margin: calc(10px * var(--heightmultiplier));
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  //border: 2px solid blue;

  //background-color: var(--primaryColor);
  border-radius: calc(16px * var(--heightmultiplier))
    calc(0px * var(--heightmultiplier)) calc(16px * var(--heightmultiplier))
    calc(16px * var(--heightmultiplier));
  //padding:  calc(10px * var(--heightmultiplier));
  &__heading {
    width: 100%;
    height: fit-content;
    padding: calc(10px * var(--heightmultiplier));
    font-size: calc(20px * var(--heightmultiplier));
    //border: 1px solid green;
    &__tabel {
      height: calc(50px * var(--heightmultiplier));
      //border: 1px solid red;
      padding: calc(10px * var(--heightmultiplier));
      margin-top: calc(10px * var(--heightmultiplier));
      background-color: rgb(124, 124, 124);
      color: white;
      // align-items: center;
      display: flex;
      align-items: center;
      //justify-content: space-between;
      border-radius: calc(8px * var(--heightmultiplier));
      &__item {
        text-align: center;
      }
      &__itemStart {
        text-align: left;
      }
      &__itemEnd {
        text-align: right;
      }
    }
    &__rows {
      overflow-y: scroll;
      // align-items: center;
      // display: flex;
      // align-items: center;
        width: 100%;
        height: 100%;
        padding: calc(2px * var(--heightmultiplier))
          calc(10px * var(--widthmultiplier));
        font-size: calc(20px * var(--heightmultiplier));
         //border: 1px solid black;
        // align-items: center;
        // display: flex;
        // align-items: center;
        &__itemRow {
          width:100%;
          //width:fit-content;
          height: calc(45px * var(--heightmultiplier));
          //height: fit-content;
          //border: 1px solid black;
          padding: calc(10px * var(--heightmultiplier));
          //margin-top: calc(5px * var(--heightmultiplier));
          background-color: var(--secondaryColor);
          font-size: calc(18px*var(--heightmultiplier));
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-radius: calc(8px * var(--heightmultiplier));
          &:nth-of-type(even) {
            background-color: var(--primaryColor);
          }
          &__item {
            width:auto;
            height: 100%;
            text-align: center;
            // border: 1px solid red;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis; 
            &__image {
              width: auto;
              height: 100%;
              // border-radius: 50%;
            }
            &__updateButton{
              width: 50%;
              height: fit-content;
              border: none;
              // padding: calc(5px * var(--widthmultiplier))
              // calc(5px * var(--heightmultiplier));
              background-color: rgba($color: #03bd1c, $alpha: 0.1);
              //opacity: 0.5;
              color: var(--primaryTextColor);
              border-radius: calc(10px* var(--heightmultiplier));
              &:hover{
                color: white;
                background-color: rgba($color: #07920e, $alpha: 0.5);
              }
            }
          }
          &__itemEnd {
            // height: calc(40px * var(--heightmultiplier));
            // font-size: calc(40px * var(--heightmultiplier));
            text-align: center;
            //align-items: center;
            // display: flex;
            // justify-content: space-around;
            // align-items: flex-end;
            //border: 1px solid black;
            // &__action{
            //   font-size: calc(20px * var(--heightmultiplier));
            // }
            &__delete{
              color: red;
             // font-size: calc(24px * var(--heightmultiplier));
              &:hover{
               // font-size: calc(40px * var(--heightmultiplier));
                cursor: pointer;
              }
            }
          }
        }
    }
  }
}
